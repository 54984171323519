/* eslint-disable react/no-danger -- when updating this component, consider replacing dangerous jsx properties */
import type { FC, KeyboardEvent } from 'react';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import uuid from 'uuid/v4';

import { token } from '@atlaskit/tokens';
import SearchIcon from '@atlaskit/icon/core/migration/search';
import Select, { components } from '@atlaskit/select';
import type { ControlProps } from '@atlaskit/select';
import { N0, N30, N40, N300 } from '@atlaskit/theme/colors';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';

import { useSessionData } from '@confluence/session-data';
import { usePageContentId, usePageSpaceKey } from '@confluence/page-context';
import { hashDataWithSha1_DO_NOT_USE } from '@confluence/hash';

import { handleAGGSearch } from './API/handleAGGSearch';
import type { liveSearchAGGResult } from './API/handleAGGSearch';
import { LiveSearchWrapper, SearchMacro, StyledButton } from './LiveSearchElementStructure';
import { SearchResultsList } from './SearchResults/SearchResultsList';
import { i18n } from './i18n';
import { handleNavigate } from './hooks/useHandleNavigate';
import { script } from './ssr-scripts/min-script-interactive-search-input';
import type { KeyableInterface } from './types';

const CONFLUENCE_ARI = 'ari:cloud:confluence::site/';

// Value of these constants is important for proper handling of input events for live search
// in editor, see liveSearchExtensionEventHandlers.ts in @confluence/editor-extension-manager.
export const LIVE_SEARCH_SELECT_CLASSNAME_PREFIX = 'live-search-macro';
export const LIVE_SEARCH_BUTTON_CLASSNAME = 'search-macro-button';

const MediumControl = ({ children, ...props }: ControlProps<any, false>) => {
	return <components.Control {...props}>{children}</components.Control>;
};

const LargeControl = ({ children, ...props }: ControlProps<any, false>) => {
	const intl = useIntl();
	return (
		<components.Control {...props}>
			<SearchIcon
				color={token('color.icon.subtle', N300)}
				label={intl.formatMessage(i18n.searchIconLabel)}
				LEGACY_size="medium"
				spacing="spacious"
			/>
			{children}
		</components.Control>
	);
};

const mediumSizeWrapperStyles = xcss({
	display: 'flex',
	justifyContent: 'center',
	paddingTop: 'space.050',
	paddingBottom: 'space.050',
});

const largeSizeWrapperStyles = xcss({
	padding: 'space.050',
	display: 'block',
});

interface LiveSearchInterface {
	config: KeyableInterface;
}

declare global {
	interface Window {
		liveSearchInput?: KeyableInterface;
	}
}

export const LiveSearchTemplate: FC<LiveSearchInterface> = ({ config }) => {
	const scriptRef = useRef(null);
	const selectRef = useRef<any>(null);
	const { size, placeholder } = config;
	const { cloudId } = useSessionData();
	const confluenceInstance = CONFLUENCE_ARI.concat(cloudId);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [currentSpaceKey] = usePageSpaceKey();
	const [contentId] = usePageContentId();
	const [searchResults, setSearchResults] = useState<Array<any>>([]);
	const [loadState, setLoadState] = useState<boolean>(true);
	const [searchInput, setSearchInput] = useState<any>('');
	const [menuIsOpen, setMenuOpen] = useState<boolean>(false);
	const [queryVersion, setQueryVersion] = useState<number>(0);
	const [searchSessionId, setSearchSessionId] = useState<string>(uuid());
	const intl = useIntl();
	const handleClickNavigate = handleNavigate();
	const nonceValue = window.__SSR_NONCE_VALUE__;

	useEffect(() => {
		const closeResultsOnResize = () => {
			setMenuOpen(false);
		};
		window.addEventListener('resize', closeResultsOnResize);
		return () => window.removeEventListener('resize', closeResultsOnResize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateResults = useCallback(
		(results: liveSearchAGGResult[]) => {
			setSearchResults(results);
			setQueryVersion(queryVersion + 1);
		},
		[queryVersion],
	);

	const handleMenuClose = useCallback(() => {
		setSearchInput(searchInput);
		setSearchResults(searchResults);
		setQueryVersion(0);
		setMenuOpen(false);
		setSearchSessionId(uuid());
	}, [searchInput, searchResults]);

	const onChange = async (str: string) => {
		const input = str;

		setSearchInput(input);

		if (input.length > 2) {
			setLoadState(true);

			const { results, size } = await handleAGGSearch({
				searchInput: input,
				config,
				currentSpaceKey,
				confluenceInstance,
			});

			updateResults(results);
			setLoadState(false);
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'shown',
					actionSubject: 'liveSearchResults',
					actionSubjectId: 'liveSearchQueryResults',
					source: 'liveSearch',
					attributes: {
						source: 'liveSearch',
						searchSessionId,
						queryHash: hashDataWithSha1_DO_NOT_USE(input),
						queryVersion,
						queryLength: input.length,
						wordCount: input.split(/\s+/).length,
						resultCount: size,
					},
				},
			}).fire();
		} else {
			if (searchResults.length) {
				setSearchResults([]);
			}
		}
	};

	const navigateHandler = useCallback(
		(obj: any) => {
			const trigger = obj?.searchMore
				? 'menuClick'
				: obj?.nativeEvent?.type === 'submit'
					? 'shortcut'
					: 'click';
			// will need to apply the same filter setting when navigate to advance page
			handleClickNavigate({
				searchMore: searchInput,
				searchResult: obj.searchResult,
				currentSpaceKey,
				config,
				eventParams: {
					wordCount: searchInput.split(/\s+/).length,
					queryLength: searchInput.length,
					queryHash: hashDataWithSha1_DO_NOT_USE(searchInput),
					trigger,
					queryVersion,
					searchSessionId,
					pageId: contentId,
				},
			});
		},
		// Only searchInput and config may change
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[searchInput, config],
	);

	const handleActivateButtonOnKeydown = (event: KeyboardEvent) => {
		// Keyboard support for button: https://www.w3.org/TR/wai-aria-practices/examples/button/button.html
		// event.key spec: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
		const ACTIVATE_BUTTON_KEYS = ['Enter', 'Spacebar', ' '];

		// Stop the propagation of these keyboard events so users can activate the button using the keyboard without the editor handling it
		if (ACTIVATE_BUTTON_KEYS.includes(event?.key)) {
			event?.stopPropagation();
			event?.preventDefault();

			navigateHandler(event);
		}
	};

	const handleKeyDownOverride = useCallback((e: any) => {
		if ((e?.metaKey || e?.ctrlKey) && e?.key === 'a') {
			e.stopPropagation();
		}
	}, []);

	const MenuList = (props: any) => {
		// corresponds to Search result format LSM setting
		const { additional } = config;
		const extraInfo =
			additional === 'page excerpt' ? 'excerpt' : additional === 'none' ? 'none' : 'space';

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			<div style={props}>
				<SearchResultsList
					searchResults={searchResults}
					searchInput={searchInput}
					onClick={navigateHandler}
					extraInfo={extraInfo}
					loading={loadState}
				/>
			</div>
		);
	};

	const focusSelect = () => {
		selectRef.current?.focus();
	};

	return (
		<LiveSearchWrapper
			title={intl.formatMessage(i18n.liveSearchTooltip)}
			data-fabric-macro={config?.macroId}
			data-macro-body
			data-macro-parameters={JSON.stringify({ currentSpaceKey, ...config })}
		>
			<SearchMacro isLarge={size === 'large'}>
				<form onSubmit={navigateHandler}>
					<Box xcss={size === 'large' ? largeSizeWrapperStyles : mediumSizeWrapperStyles}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- Ignored via go/DSP-18766 */}
						<div className="search-macro-query" onClick={focusSelect}>
							<Select
								classNamePrefix={LIVE_SEARCH_SELECT_CLASSNAME_PREFIX}
								ref={selectRef}
								placeholder={placeholder || intl.formatMessage(i18n.liveSearchLabel)}
								inputValue={searchInput}
								onInputChange={(input) => {
									void onChange(input);
								}}
								onKeyDown={handleKeyDownOverride}
								menuIsOpen={searchInput.length > 2 ? menuIsOpen : false}
								styles={{
									menuPortal: (base) => ({ ...base, zIndex: 2 }),
									menu: (base) => ({
										...base,
										overflowY: 'auto',
										minWidth: '283px',
									}),
									placeholder: (base) => ({
										...base,
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
									}),
									control: (base, state) => ({
										...base,
										paddingLeft: size === 'large' ? token('space.050', '4px') : '0px',
										backgroundColor: token('color.background.input', N0),
										border: state.isFocused
											? `2px solid ${token('color.border.selected', '#5099f9')}`
											: `2px solid ${token('color.border', N40)}`,
										borderRadius: '5px',
										':hover': {
											backgroundColor: state.isFocused
												? token('color.background.input', N0)
												: token('color.background.input.hovered', N30),
											border: state.isFocused
												? `2px solid ${token('color.border.selected', '#5099f9')}`
												: `2px solid ${token('color.border', N40)}`,
											borderRadius: '5px',
										},
									}),
									valueContainer: (base) => ({
										...base,
										paddingLeft:
											size === 'large' ? token('space.025', '2px') : token('space.100', '8px'),
									}),
								}}
								maxMenuHeight={400}
								components={{
									MenuList,
									DropdownIndicator: () => null,
									Control: size === 'large' ? LargeControl : MediumControl,
								}}
								menuPortalTarget={typeof document !== 'undefined' ? document.body : null}
								onMenuOpen={() => setMenuOpen(true)}
								onMenuClose={() => handleMenuClose()}
							/>
						</div>
						{size !== 'large' && (
							<StyledButton
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								className={LIVE_SEARCH_BUTTON_CLASSNAME}
								iconBefore={
									<SearchIcon
										color={token('color.icon.subtle', N300)}
										label={intl.formatMessage(i18n.searchIconLabel)}
										LEGACY_size="medium"
										spacing="spacious"
									/>
								}
								onClick={navigateHandler}
								onKeyDown={handleActivateButtonOnKeydown}
								appearance="subtle"
							>
								<FormattedMessage {...i18n.liveSearchLabel} />
							</StyledButton>
						)}
					</Box>
					<script
						{...(nonceValue !== undefined ? { nonce: nonceValue } : {})}
						data-test-id="ssr-scripts-init"
						ref={scriptRef}
						dangerouslySetInnerHTML={{ __html: script }}
					/>
				</form>
			</SearchMacro>
		</LiveSearchWrapper>
	);
};
